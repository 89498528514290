<template>
  <div class="about">
    <h1>This is an about pageaaaaaaa</h1>
  </div>
</template>

<style lang="scss" scoped>
  h1 {
    width: 100px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
</style>